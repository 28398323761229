import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Home from './pages/Home/Home';
import About from './pages/About/About'
import ContactUs from './pages/ContactUs/ContactUs';
import Player_Requests from './pages/Requests/Player_Requests';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Privacy from './pages/Privacy/Privacy';
import TermsOfService from './pages/Terms/Terms';



function App() {
  return (
    <Router>
      <div>
      <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about-crickai" element = {<About />} />
          <Route exact path="/contact-crickai" element = {<ContactUs />} />
          <Route exact path="/privacypolicy" element = {<Privacy />} />
          <Route exact path="/termsofservice" element = {<TermsOfService />} />
          <Route exact path="/player_requests" element = {<Player_Requests />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
