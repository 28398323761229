import React, { useRef } from "react";
import Carousel from "react-multi-carousel";
// import Profile1 from '../../assets/profile1.png'
import Mukund from '../../assets/mukund.jpg'
import Sunil from '../../assets/sunil.jpg'
import Bharat from '../../assets/bharat.jpg'
import {IoIosArrowForward, IoIosArrowBack} from 'react-icons/io'
import "react-multi-carousel/lib/styles.css";
import './mycarousel.css'

export default function MyCarousel() {
  const carouselRef = useRef()
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const items = [
    {
      img: Mukund,
      name: 'Mukund Suresh',
      title: 'President, SDCCYA',
      description: '"CrickAI has done a tremendous job for us with their streaming service. The quality of their live streaming has made it an enjoyable experience for everyone in our academy and their extended families around the world. They are very approachable, listened to and worked on the feedback we gave. They are constantly improving their service and have now incorporated an excellent highlights package per player. The streaming and highlights package enables coaches to review videos post-game to fine tune skills and game plan. I would strongly recommend CrickAI as a streaming partner for any academy looking to expand their offerings to include video as their coaching/game services."',
    },
    {
      img: Sunil,
      name: 'Sunil Mehra',
      title: 'Woodley Youth Cricket Academy',
      description: '"We wanted the participants\' friends and relatives, both near and far, to become involved in the Thanksgiving Tournament 2021 and have a good time watching the games. CrickAI\'s one-of-a-kind arrangement provided us with the opportunity to observe the action taking place on the field in great definition, and this played a significant role in enabling us to accomplish our goal of broadcasting approximately 70 to 80 matches in only 8 days."',
    },
    {
      img: Bharat,
      name: 'Bharat Patil',
      title: 'Super XI, Seattle',
      description: '"Amazing high quality service with low cost especially for Youth when they travel different places to play cricket games and their parents back home want to watch them play. One of our kid’s mom watched all these games while cooking and was super thankful to CrickAI for the livestreaming. We have also used this service for U13 zonal trial games where couple of selectors were travelling but livestreaming & later recording on YouTube helped them & everyone to watch the game multiple times."',
    },
    // {
    //   img: Profile1,
    //   name: 'Guy Hawkins',
    //   title: 'President of Sales',
    //   description: '“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet vitae lacus sed sed elit risus ut. Interdum ac porta sapien, fringilla ultrices faucibus et gravida. At ut rhoncus, blandit mauris, sit. Purus lectus metus, mi, aenean pretium ultricies consectetur nibh. Vehicula cursus enim hendrerit tristique. Ipsum et mi nisl non et”',
    // },
    // {
    //   img: Profile2,
    //   name: 'Wade Waren',
    //   title: 'Medical Assistant',
    //   description: '“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet vitae lacus sed sed elit risus ut. Interdum ac porta sapien, fringilla ultrices faucibus et gravida. At ut rhoncus, blandit mauris, sit. Purus lectus metus, mi, aenean pretium ultricies consectetur nibh. Vehicula cursus enim hendrerit tristique. Ipsum et mi nisl non et”',
    // },
    // {
    //   img: Profile1,
    //   name: 'Guy Hawkins',
    //   title: 'President of Sales',
    //   description: '“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet vitae lacus sed sed elit risus ut. Interdum ac porta sapien, fringilla ultrices faucibus et gravida. At ut rhoncus, blandit mauris, sit. Purus lectus metus, mi, aenean pretium ultricies consectetur nibh. Vehicula cursus enim hendrerit tristique. Ipsum et mi nisl non et”',
    // },
    // {
    //   img: Profile2,
    //   name: 'Wade Waren',
    //   title: 'Medical Assistant',
    //   description: '“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet vitae lacus sed sed elit risus ut. Interdum ac porta sapien, fringilla ultrices faucibus et gravida. At ut rhoncus, blandit mauris, sit. Purus lectus metus, mi, aenean pretium ultricies consectetur nibh. Vehicula cursus enim hendrerit tristique. Ipsum et mi nisl non et”',
    // },
  ]
  
  function nextSlide(){
    const slide = carouselRef.current.state.currentSlide + 1
    carouselRef.current.next()
    carouselRef.current.goToSlide(slide)
  }
  function prevSlide(){
    const slide = carouselRef.current.state.currentSlide -1
    carouselRef.current.previous()
    carouselRef.current.goToSlide(slide)
  }
  return (
    <div className='mycarouselCont'>
      
      <IoIosArrowBack className='prevbtn' onClick={prevSlide} />
      <button  onClick={prevSlide}></button>
      <Carousel ref={carouselRef} responsive={responsive} autoPlaySpeed={60000}>
       {
         items.map((item,i)=>{
           return (
             <div className='content' key={i}>
               <img className="circular--square" src={item.img} alt="" width={55} height={55} />
               <p className='mt-3'>{item.description}</p>
               <div>
                 <h5 className='text-dark'>{item.name}</h5>
                 <p style={{fontStyle:'italic'}}>{item.title}</p>
               </div>
             </div>
           )
         })
       }
       
      </Carousel>
      <IoIosArrowForward className='nextbtn' onClick={nextSlide} />
      
    </div>
  )
}
