import React from "react";
import Logo from "../../assets/logo.png";
import "./myfooter.css";
import { Col, Container, Row } from "react-bootstrap";
import {FaFacebookF, FaTwitter, FaLinkedin, FaYoutube} from "react-icons/fa";

export default function MyFooter() {
    return (
        <div className="footerCont">
        <Container>
          <Row>
            <Col lg={3} style={{padding:'0 40px'}}>
              <img style={{width:'159px', height:'81px'}} className='mb-4' src={Logo} alt="" />
              <br></br>
              <div className="social-media">
              <a href="https://www.youtube.com/channel/UCh-L-nM4rb7id1Zsr1HDavQ"><FaYoutube/></a>
              <a href="https://www.facebook.com/CrickAI"><FaFacebookF/></a>
              <a href="https://twitter.com/crick_ai"><FaTwitter/></a>
              <a href="https://www.linkedin.com/company/crickai"><FaLinkedin/></a>
              {/* <p className='gray-color'>4517 Washington Ave. Manchester, Kentucky 39495</p> */}
              </div>
            </Col>
            <Col lg={2}>
              <h6 >Company</h6>
              {/* <div>
                <a href="#">Home</a>
              </div> */}
              <div>
                <a href="./about-crickai">About CrickAI</a>
              </div>
              {/* <div>
                <a href="./">Our Services</a>
              </div> */}
              {/* <div>
                <a href="#">Features</a>
              </div> */}
              <div>
              <a href="./contact-crickai">Contact Us</a>
              </div>
            </Col>
            <Col lg={2}>
              <h6>Legal</h6>
              <div>
                <a href="./privacypolicy">Privacy Policy</a>
              </div>
              <div>
                <a href="./termsofservice">Terms of Service</a>
              </div>
            </Col>
            {/* <Col lg={2}>
              <h6>Contact Us</h6>
              <div>
                <a href="mailto:contact@crickai.com">contact@crickai.com</a>
              </div>
            </Col> */}
            {/* <Col md={6} lg={3} className='newsletter'>
              <h6>Newsletter</h6>
              <p>Never miss an update. Subscribe to our newsletter.</p>
              <div className="subscribeCont">
                <input type="text" placeholder="Email" />
                <h6 className="subscribeBtn">SUBSCRIBE</h6>
              </div>
            </Col> */}
          </Row>
          <p className='gray-color mt-5 text-center'>Copyright © 2022 CrickAI. All Rights Reserved.</p>
        </Container>
      </div>
    );
}