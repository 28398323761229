import React from "react";
import MyNavbar from "../../components/MyNavbar/MyNavbar";
import MyFooter from "../../components/MyFooter/MyFooter";
import "./contactus.css";

// import { Container, Form, Button } from "react-bootstrap";
import { Container } from "react-bootstrap";

import FormSubmission from "./contactusform";


export default function ContactUs() {
  return (
    <div>
      {/* contactUs  */}
      <MyNavbar />
      <div className="contactUsCont">
        <div className="mainContentArea">
          <h2>
            Let&apos;s figure out how we can help you engage your members more!
          </h2>
          <div className="textlines"> Just send us your questions </div>
        </div>
      </div>

      {/* Highlight  */}
      <div className="contactsContHighlight">
        <Container>
          {" "}
          <h2 className="darkblue-color mb-5">
            Get in touch with us!
          </h2>
        </Container>
        <FormSubmission />
      </div>

      {/* Footer  */}
      <MyFooter />
    </div>
  );
}
