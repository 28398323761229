import React from "react";
import MyNavbar from "../../components/MyNavbar/MyNavbar";
import MyFooter from "../../components/MyFooter/MyFooter";
import "./privacy.css";

import { Container } from "react-bootstrap";


export default function Privacy() {
  return (
    <div>
      {/* privacy  */}
      <MyNavbar />
      <div className="privacyCont">
        <div className="mainContentArea">
          <h2>
            Privacy Policy
          </h2>
          <div className="textlines"> This Privacy Policy is meant to help you understand what
            information we collect, why we collect it, and how you can update, manage, export, and delete your information.</div>
        </div>
      </div>

      {/* Highlight  */}
      <div className="highlightsCont">
        <Container>
          {/* {" "} */}
          <h4 className="darkblue-color mb-5">
            What do we collect?
          </h4>
          <div className="textlines">
            We do collect a few pieces of information to serve you better. Stay tuned to know the details!
          </div>
        </Container>


        <Container>
          {" "}
          <h4 className="darkblue-color mb-5">
            Why do we collect?
          </h4>
          <div className="textlines">
            We do collect a few pieces of information to serve you better. Stay tuned to know the details!
          </div>
        </Container>


      </div>

      {/* Footer  */}
      <MyFooter />
    </div>
  );
}
