import React from "react";
import MyNavbar from "../../components/MyNavbar/MyNavbar";
import MyFooter from "../../components/MyFooter/MyFooter";
import YoutubeEmbed from "../../components/YouTubeEmbed/YouTubeEmbed";
import "./home.css";
import HightlightImg from "../../assets/highlightImg.png";

import { Col, Container, Row } from "react-bootstrap";
import MyCarousel from "../../components/MyCarousel/MyCarousel";
// import YouTube from "react-youtube";



export default function Home() {

  return (
    <div>
      {/* Header  */}
      <MyNavbar />
      <div className="headerCont">
        <div className="mainContentArea">
          <h1>
            Never miss a cricketing moment with   <br />{" "}
            <span className="yellow-color">HD</span> Quality streaming
          </h1>
          <h4 className="gray-color mt-4 mb-4">
            Experience the moment as it unfolds, or watch highlights later
          </h4>
          <div>

            <a href="./contact-crickai"> <button>Contact Us</button> </a>
            {/* <button className="outline">WATCH VIDEO</button> */}
          </div>
        </div>
      </div>

      {/* Highlight  */}
      <div className="highlightsCont">
        <Container>
          {" "}
          <h2 className="darkblue-color mb-5">
            Rewatch your favorite game highlights <br /> on any device
          </h2>
        </Container>
        <div className="flex-reverse">
          <Container>
            <Row className="anchor">
              <Col xs={6} md={3}>
                <a href="./">Instant Share</a>
              </Col>
              <Col xs={6} md={3}>
                <a href="./">Replay</a>
              </Col>
              <Col xs={6} md={3}>
                <a href="./">HD Quality</a>
              </Col>
              <Col xs={6} md={3}>
                <a href="./">Watch Anywhere</a>
              </Col>
            </Row>
          </Container>
          <div>
            <img style={{ maxWidth: "100%" }} src={HightlightImg} alt="" />
          </div>
        </div>
      </div>

      {/* Live  */}
      <div className="liveCont">
        <Container>
          <Row>
            <Col md={5}>
              <h2 className='hideInDesktop mb-4'>Superior quality live <br /> stream.</h2>
              {/* <YouTube iframeClassName="videoContainer" videoId="s8YQ7kUS080" />  */}
              <YoutubeEmbed embedId="SfGpZdFKo94" />
            </Col>
            <Col md={5}>
              <h2 className='hideInAndroid'>Superior quality live <br /> stream.</h2>
              <p className='gray-color'>
                HD quality streaming captures action in great detail. Engage friends, family and fans with stunning live streaming and post match highlights.
              </p>
              <div className="sectionA__bottom">
                <div>
                  <h5 className='yellow-color'>HD</h5>
                  <p>Quality</p>
                </div>
                <div>
                  <h5 className='yellow-color'>Engage</h5>
                  <p>Family & Fans</p>
                </div>
                <div>
                  <h5 className='yellow-color'>Highlights</h5>
                  <p>Zoom to action</p>
                </div>
              </div>
            </Col>
          </Row>
          <Row className=" mt-5 flex-reverse">
            <Col md={5}>
              <h2 className='hideInAndroid'>Easy to use streaming <br /> platform.</h2>
              <p className='gray-color'>
                Platform built groundup just for cricket.
              </p>
              <ul className='list'>
                <li>Integrated kit to live stream action in HD quality</li>
                <li>Quick and easy setup</li>
                <li>Automatic score overlays</li>
                <li>Auto notification of the streams </li>

              </ul>
            </Col>
            <Col md={5}>
              <h2 className='hideInDesktop mb-4 mt-5'>Easy to use streaming <br /> platform.</h2>
              <YoutubeEmbed embedId="ggTHMW8iIS4" />
              
            </Col>
          </Row>
        </Container>
      </div>

      {/* Carousel  */}
      <div className="carouselCont">
        <h2 className='darkblue-color text-center mt-5'>What our customers say</h2>
        <MyCarousel />
      </div>

      {/* Sign Up  */}
      <div className="signupCont">
        <h2>Get the quality your members deserve</h2>
        {/* <p>Sign up and Stream </p> */}
        <p> </p>
        <a href="./contact-crickai"> <button>Contact Us</button> </a>
        {/* <button>SIGN UP</button> */}

      </div>

      {/* Footer  */}
      <MyFooter />
    </div>
  );
}
