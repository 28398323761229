import React from "react";
import MyNavbar from "../../components/MyNavbar/MyNavbar";
import MyFooter from "../../components/MyFooter/MyFooter";
import "./terms.css";

import { Container } from "react-bootstrap";


export default function TermsOfService() {
  return (
    <div>
      {/* privacy  */}
        <MyNavbar />
      <div className="termsCont">
        <div className="mainContentArea">
          <h2>
            Terms of Serive
          </h2>
          <div className="textlines"> Please take a moment to review our Terms of Service</div>
        </div>
      </div>

      {/* Highlight  */}
      <div className="highlightsCont">
        <Container>
          {" "}
          <h4 className="darkblue-color mb-5">
              Our Terms
          </h4>
        </Container>
        <div className="textlines">
            We use YouTube&apos;s API in our solution. Hence we are forced to link to YouTube&apos;s terms of service. Please see them <a href="https://www.youtube.com/t/terms"> here </a>
            The rest of the details will be updated soon!
        </div>
      </div>

      {/* Footer  */}
      <MyFooter/>
    </div>
  );
}
