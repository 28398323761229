import React from "react";
import { Nav, Navbar } from "react-bootstrap";
import Logo from "../../assets/logo-blue.png";
import "./mynavbar.css";
export default function MyNavbar() {
  return (
    <div>
      <Navbar expand="lg" className="navbarCont" bg='light'>
        <div className="toogleSection">
          <div>
            <a href='./'>
              <img style={{ width: "136px", height: '69px' }} src={Logo} alt="" />
            </a>
          </div>
          <div style={{ marginLeft: 'auto', display: 'flex' }}>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
          </div>
        </div>
        <div className='itemSection'>
          <div >
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto nav-items">
                <Nav.Link href="./about-crickai">About Us </Nav.Link>
                {/* <button className='signupBtn' href="./contact-crickai"> Contact Us </button> */}
                <Nav.Link href="./contact-crickai"> Contact Us </Nav.Link>
                {/* <Nav.Link href="./contact-crickai"> <button className="signupBtn"> Contact Us </button> </Nav.Link> */}
                {/* <a href="./contact-crickai"> <button>Contact Us</button> </a> */}
                {/* <Nav.Link href="#">Live Stream</Nav.Link>
                <Nav.Link href="#">Game Highlights</Nav.Link> */}
                {/* <Nav.Link href="#">Reviews</Nav.Link> */}
                {/* <Nav.Link href="#">Support</Nav.Link> */}
                {/* <Nav.Link href="#">Login</Nav.Link>
                <button className='signupBtn'>Sign Up</button> */}
              </Nav>
            </Navbar.Collapse>
          </div>
        </div>
      </Navbar>
    </div>
  );
}

