import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

// import ReactGA from 'react-ga';
// ReactGA.initialize('UA-229936828-1'); // add your tracking id here.
// ReactGA.pageview(window.location.path name + window.location.search);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
