import React from "react";
import "./contactus.css";

import { Container, Form, Button } from "react-bootstrap";
// import { useNavigate } from 'react-router-dom'

export default class FormSubmission extends React.Component {

    initialState = {
        name: '',
        email: '',
        phone: '',
        message: '',
    };
    constructor(props) {
        super(props);
        this.placeholders = {
            name: 'Enter your full name ',
            email: 'Enter your email ID',
            phone: 'Enter your phone number',
            message: 'Tell us how we can help you',
        };

        this.initialState = {
            name: '',
            email: '',
            phone: '',
            message: '',
        };

        this.state = this.initialState;
        this.error = this.initialState;
    }

    clearState() {
        this.setState({ ...this.initialState });
    }

    handleContactCrickAI(event) {

        var formdata = new FormData();
        formdata.append("name", this.state.name);
        formdata.append("email", this.state.email);
        formdata.append("phone", this.state.phone);
        formdata.append("message", this.state.message);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        // let navigate = useNavigate();

        fetch("https://script.google.com/macros/s/AKfycbwUPe_EMdfw9VjULmg4gcPONX_k3DmUvxahDoLajpG5eUh5_NFeYS-QnsMF8u6YZqj6cA/exec", requestOptions)
            .then(response => response.text())
            .then(alert("We got your message. We'll be in touch shortly"))
            .catch(error => console.log('error', error));

        this.clearState();

        event.preventDefault();
        // navigate("./");


    }
    handleOnChangeContactUsForm = (event) => {

        event.preventDefault();

        let emailRegEx = RegExp(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/);
        let phoneRegEx = RegExp(/\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/);
        
        let { name, value } = event.target;
        // let error = { ...this.error };


        this.setState({
            [event.target.name]: event.target.value
        })
        switch (name) {
            case "name":
                this.error.name = value.length < 2 ? "Name should be at least 2 characters long": " ";
                break;
            case "email":
                this.error.email = emailRegEx.test(value)? " " : "Please enter valid email address"
                break;
            case "phone":
                this.error.phone = phoneRegEx.test(value)? " " : "Please enter valid phone number"
                break;
            case "message":
                this.error.message = value.length < 10? "Message should be at least 10 characters long" : " ";
                break;
        }
        // this.setError( {
        //     error,
        //     [name]: value
        // })


    }

    render() {
        return (
            <div>

                <div className="contactUsFormArea">
                    <Container>
                        <Form onSubmit={this.handleContactCrickAI.bind(this)}>
                            <Form.Group className="mb-3" controlId="formName">
                                {/* <Form.Label className="text-dark">Name</Form.Label> */}
                                <Form.Control type="text" value={this.state.name} placeholder="Name" onChange={this.handleOnChangeContactUsForm} name="name" />
                                <Form.Text className="text-warning"> {this.error.name} </Form.Text>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                {/* <Form.Label className="text-dark">Email address</Form.Label> */}
                                <Form.Control type="email" value={this.state.email} placeholder="Email Address" onChange={this.handleOnChangeContactUsForm} name="email" />
                                <Form.Text className="text-warning"> {this.error.email} </Form.Text>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formPhoneNumber">
                                {/* <Form.Label className="text-dark">Phone Number</Form.Label> */}
                                <Form.Control type="Phone No" value={this.state.phone} placeholder="Phone Number" onChange={this.handleOnChangeContactUsForm} name="phone" />
                                <Form.Text className="text-warning"> {this.error.phone} </Form.Text>
                                <Form.Text className="text-muted">
                                   <p> We&apos;ll never share your email or phone number with anyone else. </p>
                                </Form.Text>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formMessage">
                                {/* <Form.Label className="text-dark">Message</Form.Label> */}
                                <Form.Control as="textarea" rows={6} value={this.state.message} placeholder="Tell us how we can help.." onChange={this.handleOnChangeContactUsForm} name="message" />
                                <Form.Text className="text-warning"> {this.error.message} </Form.Text>
                            </Form.Group>
                            <div className="textlines"> By clicking &lsquo;Submit&rsquo; button, I agree to be contacted by CrickAI for
                                further communication, and that I may be placed in their email list. </div>
                            <br></br>

                            <Button variant="secondary" type="submit">
                                Submit
                            </Button>
                            {/* <div className="textlines"> {this.state.result}</div> */}
                        </Form>
                    </Container>
                </div>
                {/* <br> </br> */}
                {/* <div className="contactUsMessage"> {this.state.message} </div> */}



            </div>
        );
    }
}
