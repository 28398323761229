import React from "react";
import MyNavbar from "../../components/MyNavbar/MyNavbar";
import MyFooter from "../../components/MyFooter/MyFooter";
import "./about.css";

import { Container } from "react-bootstrap";


export default function About() {
  return (
    <div>
      {/* about  */}
        <MyNavbar />
      <div className="aboutCont">
        <div className="mainContentArea">
          <h2>
            Hello there!
          </h2>
          <div className="textlines"> You want to know who we are.. Well here it is... We&apos;re a team of people 
          who believe the game of cricket can be enjoyed and improved by having access to high quality game footage. 
          We enable this by providing HD quality live streaming for cricket leagues and tournaments to 
           get friends, families, and fans to engage in the game, and not to miss a moment. Post match, CrickAI creates
            customized highlights for players and coaches so they can analyze and improve the game</div>
        </div>
      </div>

      {/* Highlight  */}
      <div className="highlightsCont">
        <Container>
          {" "}
          <h2 className="darkblue-color mb-5">
              Our Story
          </h2>
        </Container>
        <div className="textlines">
            We were disappointed with the solutions available out there for streaming our 
            kids&apos; cricket matches. We were forced to stitch together a multitude of disjoint 
            pieces of solution that hardly worked, and when it did provided poor quality streaming. 
            That&apos;s when we decided it is time to built live streaming solutions, including the hardware
            kit from groundsup, just for cricket. Thus born our company, and our product. 
        </div>
      </div>

      {/* Footer  */}
      <MyFooter/>
    </div>
  );
}
