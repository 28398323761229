import React from "react";

import MyNavbar from "../../components/MyNavbar/MyNavbar";
import MyFooter from "../../components/MyFooter/MyFooter";
import "./Player_Requests.css";

export default function Player_Requests() {
  return (
    <div>
        <MyNavbar />
        <div className="playerRequestsCont">
        <div className="mainContentArea">
      
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSf5xMxvyt6A4KHwUtfJew1U_UdPWdYwOMZpSSP0KHRZPl1myA/viewform?embedded=true" width="1280" height="1888" frameBorder="0" marginHeight="0" marginWidth="0">Loading…</iframe>
        </div>
        </div>
        <MyFooter />
    </div>
  );
}
